export const DataColor1=[
    {
        color:'Red',
        description:'lorem ipson .....',
        backgroundcolor:'red'
    },
    {
        color:'Lightsalmon',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(255,160,122)'
    },
    {
        color:'Salmon',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(250,128,114)'
    },
    {
        color:'Indianred',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(205,92,92)'
    },
    {
        color:'Firebrick',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(178,34,34)'
    },
    {
        color:'Coral',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(255,127,80)'
    },
    {
        color:'Gold',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(255,215,0)'
    },
    {
        color:'Orange',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(255,165,0)'
    },
    {
        color:'Darkorange',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(255,140,0)'
    }
];

export const DataColor2=[
    {
        color:'Gainsboro',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(220,220,220)'
    },
    {
        color:'Lightgray',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(211,211,211)'
    },
    {
        color:'Silver',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(192,192,192)'
    },
    {
        color:'Darkgray',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(169,169,169)'
    },
    {
        color:'Gray',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(128,128,128)'
    },
    {
        color:'Dimgray',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(105,105,105)'
    },
    {
        color:'Lslategray',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(119,136,153)'
    },
    {
        color:'Slategray',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(112,128,144)'
    },
    {
        color:'Dslategray',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(47,79,79)'
    },
    {
        color:'White',
        description:'lorem ipson .....',
        backgroundcolor:'white'
    }
];

export const DataColor3=[
    {
        color:'lightblue',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(173,216,230)'
    },
    {
        color:'skyblue',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(135,206,250)'
    },
    {
        color:'deepskyblue',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(0,191,255)'
    },
    {
        color:'lightsteelblue',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(176,196,222)'
    },
    {
        color:'dodgerblue',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(30,144,255)'
    },
    {
        color:'steelblue',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(70,130,180)'
    },
    {
        color:'royalblue',
        description:'lorem ipson .....',
        backgroundcolor:'rgb(65,105,225)'
    },
    {
        color:'blue',
        description:'lorem ipson .....',
        backgroundcolor:'blue'
    },
    {
        color:'darkblue',
        description:'lorem ipson .....',
        backgroundcolor:'darkblue'
    },
    {
        color:'slateblue',
        description:'lorem ipson .....',
        backgroundcolor:'slateblue'
    }
];
