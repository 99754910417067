import { useState } from 'react';
import './Header.css'
// react bootstrap
import Col from 'react-bootstrap/Col';
//animatio text import
import { TypeAnimation } from 'react-type-animation';

//swiper packeage
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectFlip, Pagination, Navigation } from 'swiper/modules';

// import images
import img from '../../asset/aboutUs/nucoatingblackLogo.png'
// aos animation
import AOS from 'aos';
import 'aos/dist/aos.css';

import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default function Header(){
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 700, 
    });
    const[TextColor , setTextColor]=useState('black')
    return(
        <>
            <div className='header-section d-md-flex justify-center align-center'>
            <div className='d-md-flex w-100 h-100 justify-content-center align-items-center'>
                <Col md={6}>
                <div className='header-title  d-flex flex-column align-items-start'>
                    <div
                        className='title-fontSize font-style fw-bold'
                        style={{
                          fontSize: '18px',
                          color: TextColor,
                        }}
                    >
                        <TypeAnimation
                            preRenderFirstString={true}
                            sequence={[
                              'Expect from us your wildest designs',
                              2000,
                              () => setTextColor('white'),
                              'Expect from us the best ',
                              2000,
                              () => setTextColor('white'),
                              'Expect from us what you desire ',
                              2000,
                              () => setTextColor('white'),
                            ]}
                            speed={50}
                            repeat={Infinity}
                        />
                    </div>
                    <div className='d-md-flex d-md-block w-100 justify-content-between'>
                        <p className='description' data-aos="fade-right">
                          The NuCoat Coating team provides the best color services in the fastest time and at the best price in the DMV . Our team has a brilliant history in the coating area in all branches (automotive, appliances, building materials, etc.) We are ready to serve people who want the highest quality and the best service as soon as possible. To receive services from us, be sure to go to the get a quote section and enter your information so that we can contact you as soon as possible
                        <Link to={'/getQuote'}><Button className='mt-3 d-block w-100'>Request a Quote</Button></Link>
                        </p>
                        
                    </div>
                </div>
                </Col>
                <Col md={6} data-aos="zoom-in">
                <Swiper
                  effect={'flip'}
                  grabCursor={true}
                  pagination={true}
                  navigation={true}
                  modules={[EffectFlip, Pagination, Navigation]}
                  className="swiper-personaliti-sectiopn"
                >
                  <SwiperSlide>
                    <img src={img}/>
                    <h3 className='font-style fw-bold'>Ownership</h3>
                    <p>
                      At NuCoating, managed by partners Oveis and Shayan, we bring years of expertise and a commitment to excellence. We specialize in delivering top-quality services with a focus on order, punctuality, and customer satisfaction. Our primary goal is to ensure every client is completely satisfied, offering a full guarantee for all our services.
                      In 2024, we established the PowderCoating team to provide superior services from highly specialized professionals. To reach us, feel free to use our email or contact number.
                    </p>
                  </SwiperSlide>
                </Swiper>
                </Col>
            </div>
            </div>
        </>
    )
}